import React, {useState, useEffect} from 'react';
import ReactLoading from "react-loading";
import {Col, Row} from "reactstrap";
import Swiper from 'react-id-swiper';
import Paper from "@material-ui/core/Paper/Paper";
import Lightbox from "react-images";
import './OCVPhotoGallery.css';

export default function OCVPhotoGallery(props: any) {
    const [galleryData, setGalleryData] = useState<any>(null);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event: {preventDefault: () => void}, index : number) => {
        event.preventDefault();
        setCurrentImage(index);
        setLightboxIsOpen(true);
    };
    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };
    const gotoPrevious = () => {
        setCurrentImage(currentImage - 1);
    };
    const gotoNext = () => {
        setCurrentImage(currentImage + 1);
    };

    useEffect(() => {
        try {
            fetch(props.link)
                .then(response => response.json())
                .then(data => {
                    setGalleryData(data['entries']);
                });
        } catch (e) {
            console.log(e);
        }
    }, [props.link]);

    let images: any[] = [];
    if (galleryData !== null) {
        for (let i = 0; i < galleryData.length; i++) {
            images.push({src: galleryData[i].image.large});
        }
    }
    if (galleryData === null) {
        return <div className='OCVPhotoGalleryDiv'><ReactLoading className='loading-centered' type={"bars"} color={'#FFF'} height={'10%'} width={'10%'} /></div>;
    } else {
        const params = {
            parallax: true,
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            autoHeight: true
        };
        return (
            <>
                <Row>
                    {(galleryData.length !== 0)
                        ? <Col className='OCVPhotoGalleryCol' xs={10} sm={10} md={10}>
                            <Paper className='OCVPhotoGalleryPaper'>
                                <Swiper className={"thumbs"} {...params}>
                                    {galleryData.map((entry: any, index: number) => (
                                        <div key={index}><img onClick={(e) => openLightbox(e, index)} alt={entry.title} className='OCVPhotoGalleryImage' src={entry.image.large} /></div>
                                    ))}
                                </Swiper>
                            </Paper>
                            <Lightbox onClickNext={gotoNext} onClickPrev={gotoPrevious}
                                backdropClosesModal currentImage={currentImage}
                                onClose={closeLightbox} isOpen={lightboxIsOpen}
                                images={images}
                            />
                        </Col> : ""}
                </Row>
            </>
        );
    }
};