import React from 'react'

export default function PageView(props: any) {
  const [pageData, setPageData] = React.useState<any>([])
  React.useEffect(() => {
      fetch(props.manifestData['features'][props.viewData['feature']]['url'], {mode: 'cors'})
          .then(response => response.json())
          .then( data => {
              setPageData(data);
          });
  }, [props.manifestData, props.viewData])
    return (
        <div>
          {props.manifestData['features'][props.viewData['feature']]?.subtypes[0] === 'imageAtTop' && (
            <>
            <div className="relative mt-6">
            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
              <div className="flex-1"></div>
              <div className="flex-1 w-full bg-white"></div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-row justify-center">
              <img className="relative rounded-full h-48 w-48 shadow-lg" src={pageData?.data?.images[0].large} alt="Page Img" />
            </div>
          </div>
          <div className="mt-4 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
          <div className="text-center">
          <h1 className="text-2xl flex flex-col items-center tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                <span className="block">{pageData?.data?.title}</span>
                <div style={{ borderColor: props.viewData.divideColor}} className="border-b-4 mt-3 w-1/5 justify-self-center"></div>
              </h1>
            <div dangerouslySetInnerHTML={{__html: pageData?.data?.content}} className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            </div>
          </div>
        </div>
        </>
          )}
          
          {props.manifestData['features'][props.viewData['feature']]?.subtypes[0] !== 'imageAtTop' && (
            <>
            <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
            <h1 className="text-2xl flex flex-col items-center tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                <span className="block">{pageData?.data?.title}</span>
                <div style={{ borderColor: props.viewData.divideColor}} className="border-b-4 mt-3 w-1/5 justify-self-center"></div>
              </h1>
              <div dangerouslySetInnerHTML={{__html: pageData?.data?.content}} className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              </div>
            </div>
          </div>
            <div className="relative mt-4">
            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
              <div className="flex-1"></div>
              <div className="flex-1 w-full bg-white"></div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-row justify-center">
              <img className="relative rounded-full h-48 w-48 shadow-lg" src={pageData?.data?.images[0].large} alt="Page Img" />
            </div>
          </div>
          </>
          )}
        </div>
    )
}