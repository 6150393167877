import { API } from 'aws-amplify';
import React from 'react'
import ReactLoading from "react-loading";

export default function FormFooter(props: any) {
    const { footerConfig, formConfig } = props;
    const [formData, setFormData] = React.useState<any>({})
    const [formFields, setFormFields] = React.useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const handleFormChange = (event: any) => {
        event.persist()
        setFormFields(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
    }
    React.useEffect(() => {
        try {
          fetch(formConfig.url, { mode: "cors" })
            .then((response) => response.json())
            .then((data) => {
              setFormData(data);
            });
        } catch (e) {
          console.log(e);
        }
      }, [formConfig.url]);
    const handleFormSubmit = () => {
        setIsSubmitting(true)
        API.post("ocvapps", "/form/submission", {
            body: {
                appID: formData.appID,
                data: {
                    formID: formConfig.formID,
                    formData: formFields
                }
            },
            headers: {
                "x-api-key": "AJgsD4mQAY95dQiaJecac3WBvEFlqnvn3vAxI93f",
        "Content-Type": "application/json",
            }
        })
        .then((response) => {
            console.log(response)
            if (response.response.statusCode === 200) {
              setIsSubmitting(false);
              setIsSubmitted(true);
            } else {
              setIsSubmitting(false);
              setIsSubmitted(false);
              console.log(response);
              alert(
                "There has been a problem with your form submission. Contact the Web Administrator for help."
              );
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            setIsSubmitted(false);
            alert(error);
            console.log(error);
          });
    }
    
    return (
        <div className="relative w-full bg-white">
            <div className="relative w-full mx-auto lg:grid lg:grid-cols-2">
                <div className="bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 lg:py-12 xl:pr-12">
                    <div className="max-w-lg mx-auto">
                        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                        {footerConfig.title}
                        </h2>
                        <dl className="mt-8 text-base text-gray-500">
                            <div>
                                <dt className="sr-only">Postal address</dt>
                                <dd>
                                    <p>{footerConfig.address.split(',')[0]}</p>
                                    <p>{footerConfig.address.split(',')[1]}, {footerConfig.address.split(',')[2]}</p>
                                </dd>
                            </div>
                            <div className="mt-6">
                                <dt className="sr-only">Phone number</dt>
                                <dd className="flex">
                                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                    </svg>
                                    <span className="ml-3">
                                        {footerConfig.phone}
                                    </span>
                                </dd>
                            </div>
                            <div className="mt-3">
                                <dt className="sr-only">Email</dt>
                                <dd className="flex">
                                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                    <span className="ml-3">
                                        {footerConfig.email}
                                    </span>
                                </dd>
                            </div>
                        </dl>
                        <p className="mt-6 text-base text-gray-500">
                        {footerConfig.description}
                        </p>
                    </div>
                </div>
                <div className="bg-gray-50 py-8 px-4 sm:px-6 lg:py-12 lg:px-8 xl:pl-12">
                <div className="max-w-lg mx-auto lg:max-w-none">
                <h1 className="mb-2 text-xl font-semibold">{formConfig.title}</h1>
                    {!isSubmitting && !isSubmitted ? (
                        <form action="#" method="POST" className="grid grid-cols-1 gap-y-4">
                            <div>
                                <label htmlFor="name" className="sr-only">Full name</label>
                                <input onChange={(e) => handleFormChange(e)} type="text" name="name" id="name" autoComplete="name" className="block w-full shadow-sm py-2 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" placeholder="Full name" />
                            </div>
                            <div>
                                <label htmlFor="email" className="sr-only">Email</label>
                                <input onChange={(e) => handleFormChange(e)} id="email" name="email" type="email" autoComplete="email" className="block w-full shadow-sm py-2 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" placeholder="Email" />
                            </div>
                            <div>
                                <label htmlFor="phone" className="sr-only">Phone</label>
                                <input onChange={(e) => handleFormChange(e)} type="text" name="phone" id="phone" autoComplete="tel" className="block w-full shadow-sm py-2 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" placeholder="Phone" />
                            </div>
                            <div>
                                <label htmlFor="message" className="sr-only">Message</label>
                                <textarea onChange={(e) => handleFormChange(e)} id="message" name="message" rows={4} className="block w-full shadow-sm py-2 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" placeholder="Message"></textarea>
                            </div>
                            <div>
                                <button type="submit" onClick={handleFormSubmit} style={{color: formConfig.buttonTextColor, background: formConfig.buttonColor}} className="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Submit
                                </button>
                            </div>
                        </form>  
                    ) : isSubmitting ? (
                        <div className="OCVFormDiv">
              <ReactLoading
                className="loading-centered"
                type={"bars"}
                color={"#000"}
                height={"10%"}
                width={"10%"}
              />
            </div>
                    ) : ("")}
                    {isSubmitted ? (
            <h2 className="font-semibold text-lg">Form Submitted! Thank You!</h2>
          ) : (
            ""
          )}
                    </div>
                </div>
            </div>
        </div>
    )
}