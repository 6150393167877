import React, {useState, useEffect} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import OCVBlogContainer from "./OCVBlogContainer/OCVBlogContainer";
import OCVBlogDetail from "./OCVBlogDetail/OCVBlogDetail";

export default withRouter(function OCVBlog(props: any) {
    const [blogData, setBlogData] = useState([]);

    useEffect(() => {
        fetch(props.link, {mode: 'cors'})
            .then(response => response.json())
            .then( data => {
                setBlogData(data);
            });
    }, [props.link]);

    const {blogLink, classes, route, subtypes, submitATipLink, title} = props;
    return(
        <Switch>
            <Route exact path="/" render={() => (<Redirect to="/" />)} />
            <Route exact path={'/' + route} render={() => <OCVBlogContainer subtypes={subtypes} submitATipLink={submitATipLink} title={title} link={blogLink} classes={classes} route={route} blogData={blogData} perPage={10} />} />
            {(blogData.length !== 0) ? <Route path={'/' + route + '/:id'} render={() => <OCVBlogDetail subtypes={subtypes} blogEntries={(blogData.length !== 0) ? blogData : []} route={route} />} /> : ' '}
        </Switch>
    );
});