import React from "react";
import {Col, Row} from "reactstrap";
import Paper from "@material-ui/core/Paper/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {withRouter} from "react-router";
import './OCVSubmenuPage.css';

export default withRouter(function OCVSubmenuPage(props: any) {
    const {items, manifestData, classes, history} = props;
    return (
        <Row>
            <Col className='OCVSubmenuPageCol' xs={10}>
                <Paper className='OCVSubmenuPagePaper'>
                    <List>
                        {items.map((item: any) => (
                            manifestData['features'][item]['subtype'] 
                                ? (manifestData['features'][item]['subtype'][0] === "external" 
                                    ? <a style={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" href={manifestData['features'][item]['url']}><ListItem key={item} className={'OCVSubmenuPageListItem ' + classes.homepageButton}>{manifestData['features'][item]['title']}</ListItem></a>
                                    : <ListItem key={item} className={'OCVSubmenuPageListItem ' + classes.homepageButton} onClick={() => history.replace('/' + item)}>{manifestData['features'][item]['title']}</ListItem>) 
                                : <ListItem key={item} className={'OCVSubmenuPageListItem ' + classes.homepageButton} onClick={() => history.replace('/' + item)}>{manifestData['features'][item]['title']}</ListItem>
                        ))}
                    </List>
                </Paper>
            </Col>
        </Row>
    );
});