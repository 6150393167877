import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function SocialBar(props: any) {
    return (
        <div className="bg-opacity-25" style={{background: props.viewData.backgroundColor}}>
  <div className="max-w-7xl mx-auto py-8 px-4 sm:py-6 sm:px-6 lg:px-8">
    <div className="lg:grid lg:grid-cols-2 lg:gap-8">
      <h2 style={{color: props.viewData.titleColor}} className="max-w-md mx-auto text-3xl font-extrabold text-center lg:max-w-xl lg:text-left">
        {props.viewData.title}
      </h2>
      <div className="flow-root self-center mt-8 lg:mt-0">
        <div className=" -ml-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:flex-wrap justify-between lg:-ml-4">
          {props.viewData.socials.map((item: any) => (
            <div key={item.url} className=" ml-6 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
            <a href={item.url} rel="noreferrer noopener" target="_blank" className="block font-bold text-2xl space-x-2">
                 <FontAwesomeIcon icon={["fab", item.title.toLowerCase()]} color={props.viewData.iconColor} />
                 {props.viewData.socialText && (
                   <span style={{color: props.viewData.iconColor}}>{item.title}</span>
                 )}
              </a>
          </div>
          ))}
        </div>
      </div>
    </div>
  </div>
</div>
    )
}