import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

export default function ImageBar(props: any) {
  const { imageBarConfig, classes, manifestData } = props;
  let imageBar = imageBarConfig["items"].map((item: any, index: any) => (
    <Col
      key={item.title + index}
      className={classes.imageBarItem}
      style={{
        backgroundImage: `url(${
          manifestData["stylesheet"].images
            ? manifestData["stylesheet"].images[item.image]["url"]
            : ""
        })`,
      }}
      xs={12}
      sm={12}
      md={4}
    >
      <span className={classes.imageBarOverlay}>
        {manifestData["features"][item.feature]["type"] === "webview" &&
        manifestData["features"][item.feature]["subtype"].includes(
          "external"
        ) ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={classes.imageBarLink}
            href={manifestData["features"][item.feature]["url"]}
          >
            <h3 className={classes.imageBarText}>{item.title}</h3>
          </a>
        ) : (
          <Link className={classes.imageBarLink} to={"/" + item.feature}>
            <h3 className={classes.imageBarText}>{item.title}</h3>
          </Link>
        )}
      </span>
    </Col>
  ));
  return <Row>{imageBar}</Row>;
}