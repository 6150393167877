import React from "react";

export default function Summary(props: any) {
  const [blogData, setBlogData] = React.useState<any>([]);
  React.useEffect(() => {
    fetch(props.manifestData["features"][props.viewData["feature"]]["url"], {
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setBlogData(data);
      });
  }, [props.manifestData, props.viewData]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleDates = (date: any) => {
    const d = new Date(date * 1000);
    const newDate =
      months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
    return newDate;
  };
  return (
    <div className="bg-white pt-16 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative overflow-hidden max-w-lg mx-auto lg:max-w-7xl h-auto">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {props.viewData.title}
          </h2>
          <div
            style={{ borderColor: props.viewData.config.divideColor }}
            className="mt-3 mb-2 border-b-4 w-1/6"
          ></div>
        </div>
        <div className="mt-3 h-auto grid gap-8 pt-2 lg:grid-cols-2 lg:gap-x-24 overflow-hidden">
          <div className="flex flex-col overflow-hidden max-h-96">
            {blogData.length > 0 && (
              <>
                <h1 className="font-semibold mb-1 mt-4">
                  {handleDates(blogData[0]?.date.sec)}
                </h1>
                <div
                  style={{ borderColor: props.viewData.config.blogDivideColor }}
                  className="border-t-2 w-1/12 mb-2"
                ></div>
              </>
            )}
            <a
              href={`${props.viewData["feature"]}/${blogData[0]?._id["$id"]}`}
              className="block max-h-96"
            >
              <p className="text-xl font-semibold text-gray-900">
                {blogData[0]?.title}
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    blogData[0]?.content.length > 200
                      ? blogData[0]?.content + "<span>...</span>"
                      : blogData[0]?.content,
                }}
                className="mt-3 text-base text-gray-500"
              ></div>
            </a>
          </div>
          <div className="flex flex-col overflow-hidden max-h-96">
            {blogData.length > 0 && (
              <>
                <h1 className="font-semibold mb-1 mt-4">
                  {handleDates(blogData[1]?.date.sec)}
                </h1>
                <div
                  style={{ borderColor: props.viewData.config.blogDivideColor }}
                  className="border-t-2 w-1/12 mb-2"
                ></div>
              </>
            )}
            <a
              href={`${props.viewData["feature"]}/${blogData[1]?._id["$id"]}`}
              className="block max-h-96"
            >
              <p className="text-xl font-semibold text-gray-900">
                {blogData[1]?.title}
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    blogData[1]?.content.length > 200
                      ? blogData[1]?.content + "<span>...</span>"
                      : blogData[1]?.content,
                }}
                className="mt-3 text-base text-gray-500"
              ></div>
            </a>
          </div>
        </div>
        <div className="text-center mt-12 lg:mb-0 mb-6">
          <a href={props.viewData["feature"]} className="block w-auto">
            <button
              type="button"
              style={{
                color: props.viewData["config"]["buttonTextColor"]
                  ? props.viewData["config"]["buttonTextColor"]
                  : "white",
                background: props.viewData["config"]["buttonColor"]
                  ? props.viewData["config"]["buttonColor"]
                  : "blue",
              }}
              className="inline-flex items-center px-12 py-3 border border-transparent text-lg font-semibold rounded-xl shadow-sm focus:outline-none"
            >
              View All
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
