import {stylesheet} from "typestyle";

const webOne = (colors, theme)  => (stylesheet({
    header: {
        position: 'absolute',
        left: '0',
        right: '0',
        top: '0',
        zIndex: '100',
        background: colors.primary
    },
    navbar: {
        position: 'relative',
        left: '0',
        right: '0',
        top: '0',
        zIndex: '100',
        background: colors.primary,
        height: '70px',
        width: '100%'
    },
    logo: {
        maxWidth: '100px',
        width: '100%'
    },
    longLogo: {
        minWidth: '180px',
        maxWidth: '200px',
        width: "100%"
    },
    logoContainer: {
        display: 'inline-block',
        maxHeight: '100px',
        paddingTop: '5px'
    },
    navList: {
        margin: '0',
        listStyle: 'none',
        float: 'left'
    },
    navListItem: {
        width: 'auto !important',
        padding: '20px 10px !important',
        float: 'left',
        fontSize: '16px',
        textTransform: 'uppercase',
        "@media (max-width: 991px)": {
            width: '100% !important'
        }
    },
    navItemLink: {
        textDecoration: 'none',
        color: '#FFF',
        "&:hover": {
            color: colors.tertiary,
            textDecoration:'none'
        },
        "@media screen and (max-width: 1100px)": {
            fontSize: "0.8em"
        },
        "@media screen and (max-width: 990px)": {
            fontSize: "1em"
        }
    },
    navSubmenu: {
        position: "absolute !important",
        top: "0",
        background: colors.secondary,
        minWidth: "5rem",
        whiteSpace: "nowrap",
        transition: "300ms linear",
        transitionProperty: "opacity, visibility",
        transitionDelay: "150ms",
        boxShadow: "0 10px 0.8125rem 0 rgba(2, 3, 3, 0.24)",
        "&:hover": {
            cursor: "pointer"
        }
    },
    navSubmenuTitle: {
        display: "block",
        width: 'auto !important',
        float: 'left',
        fontSize: '16px',
        textTransform: 'uppercase',
        color: '#FFF',
        "&:hover": {
            color: colors.tertiary,
            textDecoration:'none',
            cursor: 'pointer',
            "&>*:first-child": {
                visibility: "visible",
                opacity: "1",
                display: "block"
            }
        },
        "@media (max-width: 1199.98px)": {
            width: '100% !important'
        },
        "@media screen and (max-width: 1100px)": {
            fontSize: "0.8em"
        },
        "@media screen and (max-width: 990px)": {
            fontSize: "1em"
        }
    },
    navSubmenuItem: {
        width: '100% !important',
        float: 'left',
        fontSize: '16px',
        textTransform: 'uppercase',
        borderBottom: "2px solid "+ colors.tertiary,
        "&:hover": {
            backgroundColor: colors.primary,
            borderBottom: "2px solid #FFF"
        }
    },
    navSubmenuLink: {
        textDecoration: 'none',
        color: '#FFF',
        "&:hover": {
            color: '#FFF',
            textDecoration:'none'
        }
    },
    bmBurgerButton: {
        position: 'absolute',
        width: '36px',
        height: '30px',
        left: 'initial',
        right: '36px',
        margin: '20px 0px'
    },
    bmBurgerBars: {
        background: (theme === 'dark') ? 'white' : '#000'
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenu: {
        background: colors.primary,
        padding: '2.5em 0 0',
        fontSize: '1.15em'
    },
    bmMorphShape: {
        fill: '#373a47'
    },
    bmItemList: {
        color: colors.tertiary,
        padding: '0.8em'
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0)',
        position: 'fixed',
        top: '0'
    },
    bmItem: {
        display: 'inline-block'
    },
    bmMenuWrap: {
        top: '0'
    },
    topBar: {
        position: 'relative',
        borderBottom: '5px solid ' + colors.tertiary,
        background: colors.secondary,
        height: 'auto',
        lineHeight: '0px',
        width: '100%'
    },
    topLinks: {
        float: 'left',
        textAlign: 'left',
        '@media (max-width: 1048px)': {
            textAlign: 'center'
        }
    },
    topLinksList: {
        margin: '0 !important',
        padding: '0 !important',
        listStyle: 'none',
        width: 'auto'
    },
    topLinksItem: {
        height: '40px',
        width: 'auto !important',
        padding: '10px 5px !important',
        display: 'inline-block !important',
        ":firstChild": {
            borderLeft: '0 !important'
        }
    },
    topLink: {
        display: 'block',
        padding: '10px 5px',
        fontWeight: '700',
        textTransform: 'uppercase',
        height: '45px',
        color: '#FFF',
        textDecoration: 'none',
        fontSize: '13px',
        "&:hover": {
            color: colors.tertiary,
            textDecoration:'none'
        }
    },
    topLinksButton: {
        width: "auto !important",
        display: "inline-block !important",
        padding: '10px 5px !important',
        ":firstChild": {
            borderLeft: '0 !important'
        },
        "@media (max-width: 768px)": {
            padding: '5px !important'
        }
    },
    topSocial: {
        margin: '0 ',
        padding: '0',
        listStyle: 'none',
        width: 'auto',
        textAlign: 'center'
    },
    topSocialList: {
        float: 'right',
        padding: '0px !important',
        '@media (max-width: 1048px)': {
            float: 'none !important'
        }
    },
    topSocialListItem: {
        display: 'inline-block !important',
        padding: '10px !important',
        position: 'relative',
        float: 'left',
        width: 'auto !important',
        overflow: 'hidden',
        height: '44px',
        "&:hover": {
            backgroundColor: '#b1a38c'
        },
        '@media (max-width: 1048px)': {
            float: 'none'
        }
    },
    topSocialLink: {
        float: 'none',
        width: '30px',
        display: 'inline-block',
        fontWeight: 'bold',
        color: '#fff',
        "&:hover": {
            color: '#fff',
            textDecoration:'none'
        }
    },
    slider: {
        position: "relative",
        height: "auto",
        width: "auto",
        '@media (max-width: 600px)': {
                height: '125%'
        }
    },
    innerContainer: {
        width: '100%',
        height: '100%',
        margin: '0',
        position: 'relative'
    },
    slide: {
        textAlign: 'center',
        width: '100%',
        background: '#000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    images: {
        width: '100%'
    },
    featureBar: {
        height: 'auto',
        backgroundColor: colors.primary,
        borderBottom: '5px solid '+ colors.tertiary
    },
    feature: {
        margin: '0 auto !important',
        display: 'inline-block',
        color: '#ffffff',
        padding: '10px 0',
        fontWeight: 'normal',
        textAlign: 'center',
        height: '160px',
        width: '180px',
        lineHeight: '60px'
    },
    featureBackground: {
        display: 'inline-block',
        background: colors.secondary,
        borderRadius: '50%',
        padding: '10px'
    },
    featureBackgroundImage: {
        display: 'inline-block',
        borderRadius: '50%',
        padding: '0px 5px'
    },
    featureImage: {
        margin: '0 auto !important',
        display: 'inline-block',
        color: '#ffffff',
        fontWeight: 'normal',
        borderRadius: '50%',
        height: '160px',
        width: '170px',
        padding: '10px',
        lineHeight: '60px'
    },
    featureText: {
        color: '#fff',
        fontWeight: 'normal',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'block',
        width: 'auto',
        margin: '0',
        lineHeight: 'normal'
    },
    arrowLeft: {
        position: 'absolute',
        top: '45%',
        width: '27px',
        height: '44px',
        marginTop: '-22px',
        zIndex: '10',
        cursor: 'pointer'
    },
    arrowRight: {
        position: 'absolute',
        top: '45%',
        width: '27px',
        right: '10px',
        height: '44px',
        marginTop: '-22px',
        zIndex: '10',
        cursor: 'pointer'
    },
    featureIcon: {
        display: 'block',
        margin: '0 auto',
        textAlign: 'center',
        verticalAlign: 'center'
    },
    footer: {
        background: colors.secondary,
        color: "#FFF"
    },
    footerIcon: {
        maxHeight: "125px",
        padding: "10px",
        verticalAlign: 'top'
    },
    footerInfo: {
        padding: '0px 20px',
        margin: 'auto',
        top: '50%'
    },
    footerNav: {
        textAlign: 'right',
        padding: '10px 0px',
       // position: 'absolute',
        top: '50%',
        //transform: 'translate(0, -50%)'
        '@media (max-width: 768px)': {
            textAlign: 'center'
        }
    },
    footerNavItem: {
        textDecoration: "none",
        color: '#FFF',
        "&:hover": {
            color: "#b1a38c",
            textDecoration: "none"
        }
    },
    footerSocialListItem: {
        display: 'inline-block !important',
        padding: '10px 0px !important',
        position: 'relative',
        width: 'auto !important',
        height: '40px'
    },
    blogEllipsis: {
        /* hide text if it more than N lines  */
        overflow: 'hidden',
        /* for set '...' in absolute position */
        position: 'relative',
        /* use this value to count block height */
        lineHeight: '1.2em',
        /* max-height = line-height (1.2) * lines max number (3) */
        maxHeight: '3.45em',
        /* fix problem when last visible word doesn't adjoin right side  */
        textAlign: 'justify',
        /* place for '...' */
        marginRight: '-1em',
        paddingRight: '1em',
        "&::before": {
            /* points in the end */
            content: '...',
            /* absolute position */
            position: 'absolute',
            /* set position to right bottom corner of block */
            right: '0',
            bottom: '0',
            color: "FFF"
        },
        "::after": {
            /* points in the end */
            content: '...',
            /* absolute position */
            position: 'absolute',
            /* set position to right bottom corner of text */
            right: '0',
            /* set width and height */
            width: '1em',
            height: '1em',
            marginTop: '0.2em',
            /* bg color = bg color under block */
            background: 'white'
        }
    },
    contactsContainer: {
        padding: "0 0 72px",
        //borderTop: "2px solid #001f18",
        marginTop: "10px",
        '@media only screen and (max-width: 767px)': {
            borderWidth: '1px 0 0'
        }
    },
    tabsIndicator: {
        backgroundColor: '#003227'
    },
    contactHeaderRoot : {
        overflow: "hidden"
    },
    contactsEntry: {
        overflow: "hidden",
        position: "relative",
        //padding: "48px 30px 53px",
        padding: "20px 0px",
        //borderBottom: "2px solid #001f18",
        '@media only screen and (max-width: 767px)': {
            borderWidth: '0 0 1px',
            textAlign: 'left',
            //padding: '37px 9px 43px'
            padding: '37px 0px 43px'
        }
    },
    contactsImageSection: {
        //float: 'left',
        //margin: '4px 51px 0 0',
        margin: "0",
        '@media only screen and (max-width: 767px)': {
            textAlign: "center",
            margin: "0 0 25px",
            float: "none"
        }
    },
    contactsImageContainer: {
        //float: 'right',
        //margin: '0 0 0 43px',
        margin: "0",
        '@media only screen and (max-width: 950px)': {
            margin: '0 auto 15px',
            float: 'none',
            display: 'block'
        }
    },
    contactsImage: {
        //borderRadius: '50%',
        position: 'relative',
        //verticalAlign: 'top',
        //boxShadow: '3px 3px 5px 6px #ccc'
    },
    contactsSocialContainer: {
        height: '265px',
        width: '25px',
        whiteSpace: 'nowrap',
        float: 'right',
        '@media only screen and (max-width: 950px)': {
            width: '100%',
            height: 'auto',
            float: 'none',
            textAlign: 'center'
        }
    },
    contactsSocialList: {
        padding: '0',
        margin: '0',
        listStyle: 'none',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    contactsSocialListItem: {
        margin: '17px 0 0',
        overflow: 'hidden',
        ':first-child': {
            margin: '0'
        },
        '@media only screen and (max-width: 950px)': {
            float: 'left',
            margin: '0 0 0 16px',
            ':first-child': {
                margin: '0'
            }
        }
    },
    contactsSocialLink: {
        color: '#222',
        display: 'block',
        overflow: 'hidden',
        width: '25px'
    },
    contactsTextSection: {
        overflow: "hidden",
        position: "relative",
        "@media only screen and (max-width: 767px)": {
            textAlign: "left",
            fontSize: "20px",
            lineHeight: "21px"
        }
    },
    contactsTextTitle: {
        textTransform: "uppercase",
        paddingTop: "10px",
        margin: "0 0 28px",
        fontSize: "27px",
        "@media only screen and (max-width: 767px)": {
            fontSize: "21px",
            lineHeight: "24px",
            margin: "0 0 22px"
        }
    },
    contactsTextContent: {
        margin: "0 0 23px",
        "@media only screen and (max-width: 767px)": {
            margin: "0 0 19px"
        }
    },
    breadcrumbHeader:{
        lineHeight: "1.5",
        fontWeight: 600,
        letterSpacing: "1px",
        fontSize: "32px",
        margin: "0",
        "@media (max-width: 767px)": {
            textAlign: "center"
        }
    },
    breadcrumb: {
        listStyleType:"none",
        position: "absolute",
        width: "auto",
        top:"50%",
        left: "auto",
        right:"15px",
        margin: "-10px 0 0 0",
        "@media (max-width: 767px)": {
            position: "relative",
            top: "0",
            margin: "20px 0 0",
            textAlign: "center"
        }
    },
    breadcrumbHome: {
        fontSize: "20px",
        lineHeight: "1.5"
    },
    imageBarItem: {
        textAlign: "center",
        verticalAlign: "middle",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        minHeight: "350px",
        backgroundColor: "rgba(0,0,0,0.5)",
        transition: "all .15s linear",
        height: "270px",
        padding: "60px",
        "@media (max-width: 676px)": {
            backgroundSize: "cover"
        },
        "@media (max-width: 991px)": {
            minHeight:"270px",
            height: "250px"
        },
        "&:hover": {
            backgroundSize: "120% 120%",
            textDecoration: "none"
        },
    },
    imageBarLink: {
        minHeight: "200px",
        width: "100%",
        display: "block",
        color: "white",
        "&:hover": {
            color: "white",
            textDecoration: "none"
        },
        "@media (max-width: 991px)": {
            minHeight: "150px"
        }
    },
    imageBarOverlay: {
        zIndex: 2,
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.5)"
    },
    imageBarText: {
        paddingTop: "30%",
        fontSize: "2em",
        textTransform: "uppercase",
        fontWeight: 400,
        letterSpacing: "1px"
    },
    buttonLargeOutline: {
        lineHeight: "42px",
        border: "2px solid " +  colors.primary,
        color:  colors.primary,
        backgroundColor: "#FFF",
        fontWeight: 600,
        borderRadius: "3px",
        height: "46px",
        padding: "0 26px",
        "&:hover": {
            backgroundColor:  colors.primary,
            color: "white"
        }
    },
    smallButton: {
        lineHeight: "30px",
        backgroundColor: colors.primary,
        color: "white",
        fontWeight: 600,
        borderRadius: "3px",
        height: "30px",
        padding: "0 26px",
        width: "100%",
        display: "block",
        textTransform: "uppercase",
        "@media (max-width: 613px)": {
            padding: "0 10px"
        },
        "&:hover": {
            backgroundColor: colors.tertiary,
            cursor: "pointer",
            color: "white",
            textDecoration: "none"
        }
    },
    navButton: {
        color: "white",
        fontWeight: 600,
        width: "100%",
        padding: "20px !important",
        display: "block",
        "@media (max-width: 613px)": {
            padding: "0 10px"
        }
    },
    navButtonLink: {
        borderRadius: "3px",
        padding: "0 30px !important",
        margin: "20px",
        color: "white",
        height: "28px",
        fontSize: "0.8em",
        textTransform: "uppercase",
        backgroundColor: colors.primary,
        "&:hover": {
            backgroundColor: colors.primary,
            color: "white",
            textDecoration: "none"
        }
    },
    homepageButton: {
        lineHeight: "42px",
        backgroundColor: colors.primary,
        color: "white !important",
        fontWeight: 600,
        borderRadius: "3px",
        height: "46px",
        padding: "0 26px",
        width: "100%",
        display: "block",
        "@media (max-width: 613px)": {
            lineHeight: "20px",
            padding: 0
        },
        "&:hover": {
            backgroundColor: "#444",
            cursor: "pointer"
        }
    }
}));

export default webOne;