import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "./Breadcrumb.css";

export default function Breadcrumb(props: any) {
  const { classes, title } = props;
  return (
    <Row className="breadcrumbRow">
      <Col xs={10} sm={10} md={10}>
        <h1
          style={{ width: "65vw", whiteSpace: "pre-wrap" }}
          className={classes.breadcrumbHeader}
        >
          {title}
        </h1>
        <ol className={classes.breadcrumb}>
          <li>
            <Link className={classes.breadcrumbHome} to={"/"}>
              &larr; Back to Home
            </Link>
          </li>
        </ol>
      </Col>
    </Row>
  );
}