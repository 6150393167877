import React from 'react'
import ios from "../../../assets/app-store-badge.svg"
import google from "../../../assets/google-play-badge.png"

export default function DownloadOurApp(props: any) {
    return (
        <div className="bg-white px-4 sm:px-6 lg:pb-28 lg:px-8">
            <div className="relative overflow-hidden max-w-lg mx-auto lg:max-w-7xl h-auto">
                <div className="flex flex-col items-center justify-center">
                    
                    <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                        Download Our App
                    </h2>
                    <div style={{borderColor: props.viewData.config.divideColor}} className="mt-3 mb-2 border-b-4 w-1/6"></div>
                </div>
                <div className="mt-3 h-auto grid gap-4 pt-2 lg:grid-cols-2 lg:gap-x-2 overflow-hidden">
                    <div className="flex flex-col items-center">
                        <img className=" h-auto w-1/2" src={props.viewData?.mockup} alt="Page Img" />
                    </div>
                    <div className="flex flex-col justify-center text-center items-center overflow-hidden max-h-96">
                        <p className="text-xl w-3/4">This app is another public outreach effort developed by your Sheriff's office to improve communication with residents and visitors</p>
                        <a href='/viewOurApp' className="mt-4">
                            <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-800 shadow-sm text-base font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            View More Info
                            </button>
                        </a>
                        <a href={props.viewData.config.iosLink} rel="noopener noreferrer" target={"_blank"} className=''><img className='w-40 mt-3' alt="ios" src={ios} /></a>
                            <a href={props.viewData.config.androidLink} rel="noopener noreferrer" target={"_blank"} className=''><img className='w-44 mt-1' alt="google" src={google} /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}