import React from "react";
import TextField from "@material-ui/core/TextField";
import { Row, Col } from "reactstrap";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import "./OCVMapToolbar.css";

export default function OCVMapToolbar(props: any) {
  const {
    pinSort,
    pinSortSecondary,
    legend,
    showLegend,
    showFilter,
    toggleFilter,
    toggleLegend,
    clearFilters,
    search,
    handleSearch,
    pinTypeFilter,
    pinTypeFilterChange,
    pinSortFilter,
    pinSorted,
    pinSortedSecondary,
    pinSortSecondaryFilter,
  } = props;
  return (
    <div className="OCVMapToolbarExternalDiv">
      <div className="OCVMapToolbarInternalDiv">
        <Row>
          <Col xs={12}>
            <div className="OCVMapToolbarNestedDivOne">
              <button
                onClick={toggleLegend}
                className={"OCVMapToolbarButton btn btn-primary"}
              >
                {!showLegend ? "Show Legend" : "Close Legend"}
              </button>
              {legend.length > 1 ||
              pinSort.length !== 0 ||
              pinSortSecondary.length !== 0 ? (
                <>
                  <button
                    onClick={toggleFilter}
                    className={"OCVMapToolbarButton btn btn-primary"}
                  >
                    {!showFilter ? "Show Filter" : "Close Filter"}
                  </button>
                  <button
                    onClick={clearFilters}
                    className={"OCVMapToolbarButton btn btn-primary"}
                  >
                    {"Clear Filters"}
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col
            className="OCVMapToolbarColOne"
            style={{ display: showLegend ? "block" : "none" }}
            xs={12}
          >
            <Row>
              {legend.map((item: any) => (
                <Col
                  key={item.title}
                  className="OCVMapToolbarColTwo"
                  xs={6}
                  sm={6}
                  md={4}
                >
                  <img alt={item.title} width={21} height={40} src={item.pin} />
                  &nbsp;{item.title}
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={12}>
            <TextField
              value={search}
              fullWidth
              onChange={handleSearch}
              label={"Search by Title or Address"}
              placeholder={"Search by Title or Address"}
              variant={"outlined"}
            />
          </Col>
          <Col
            xs={12}
            style={{
              display: showFilter && legend.length > 1 ? "block" : "none",
            }}
          >
            <div className="OCVMapToolbarNestedDivTwo">
              <TextField
                value={pinTypeFilter}
                onChange={pinTypeFilterChange}
                fullWidth
                label={"Filter by Pin Type"}
                select
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
              >
                {legend.map((item: any, index: string | number) => (
                  <MenuItem key={item.color + "-" + index} value={item.color}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Col>
          <Col
            xs={12}
            style={{
              display: showFilter && pinSort.length !== 0 ? "block" : "none",
            }}
          >
            <div className="OCVMapToolbarNestedDivTwo">
              <TextField
                value={pinSorted}
                onChange={pinSortFilter}
                fullWidth
                label={"Filter by Pin Type"}
                select
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
              >
                {pinSort.map((item: any, index: string | number) => (
                  <MenuItem key={item.value + "-" + index} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Col>
          <Col
            xs={12}
            style={{
              display: showFilter && pinSorted.length !== 0 ? "block" : "none",
            }}
          >
            <div className="OCVMapToolbarNestedDivTwo">
              <TextField
                value={pinSortedSecondary}
                onChange={pinSortSecondaryFilter}
                fullWidth
                label={"Filter by"}
                select
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
              >
                {pinSortSecondary.map((item: any, index: string | number) => (
                  <MenuItem key={item.value + "-" + index} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
